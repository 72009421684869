import { createContext, HTMLAttributes, useContext, useState } from 'react'

export const defaultValues: ContactInfoProviderType = {
  contactInfo: {
    firstName: '',
    lastName: '',
    socialSecurityNumber: '',
    email: '',
    phoneNumber: '',
  },
  updateContactInfo: () => {},
}

export type ContactInfoProviderType = {
  contactInfo: ContactInfo
  updateContactInfo: (arg0: ContactInfo) => void
}

export type ContactInfoProviderProps = {
  defaultContactInfo?: ContactInfo
  context: React.Context<ContactInfoProviderType>
} & HTMLAttributes<HTMLDivElement>

const ContactInfoContext = createContext(defaultValues)

export const ContactInfoProvider = ({ children, defaultContactInfo }: ContactInfoProviderProps) => {
  const [contactInfo, setContactInfo] = useState<ContactInfo>(defaultContactInfo ? defaultContactInfo : defaultValues.contactInfo)

  const updateContactInfo = (data: ContactInfo) => {
    const updatedContactInfoObject = {
      ...contactInfo,
      ...data,
    }
    setContactInfo(updatedContactInfoObject)
  }

  const contextValue: ContactInfoProviderType = {
    contactInfo: contactInfo,
    updateContactInfo: updateContactInfo,
  }

  return <ContactInfoContext.Provider value={contextValue}>{children}</ContactInfoContext.Provider>
}

export const CreateContactInfoContext = () => {
  return createContext<ContactInfoProviderType>(defaultValues)
}

export const useContactInfoContext = () => useContext(ContactInfoContext)
