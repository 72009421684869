import ActivityEnum from '@/common/enums/ActivityEnum'
import router from 'next/router'
import { createContext, HTMLAttributes, SetStateAction, useContext, useState } from 'react'

const defaultValues: CurrentStepProviderType = {
  currentStep: 1,
  serviceType: ActivityEnum.MOVEHELP,
  currentTitle: '',
  addons: [],
  updateCurrentStep: () => {
    return
  },
  incrementStep: () => {
    return
  },
  decrementStep: () => {
    return
  },
  updateServiceType: () => {
    return
  },
  updateTitle: () => {
    return
  },
  goToStep: () => {
    return
  },
  addAddons: () => {
    return
  },
  removeAddons: () => {
    return
  },
}

export type CurrentStepProviderType = {
  currentStep: number
  serviceType: string
  currentTitle: string
  addons: Array<string>
  updateCurrentStep: (arg0: number) => void
  incrementStep: () => void
  decrementStep: () => void
  updateServiceType: (type: string) => void
  updateTitle: (type: string) => void
  goToStep: (step: number) => void
  addAddons: (addon: string) => void
  removeAddons: (addon: string) => void
}

export type CurrentStepProviderProps = {
  defaultCurrentStep?: number
  defaultCurrentType?: string
  defaultCurrentTitle?: string
  defaultCurrentAddons?: Array<string>
  context: React.Context<CurrentStepProviderType>
} & HTMLAttributes<HTMLDivElement>

const CurrentStepContext = createContext(defaultValues)

export const CurrentStepProvider = ({ children, defaultCurrentStep, defaultCurrentType, defaultCurrentTitle, defaultCurrentAddons }: CurrentStepProviderProps) => {
  const [currentStep, setCurrentStep] = useState<number>(defaultCurrentStep ? defaultCurrentStep : defaultValues.currentStep)
  const [serviceType, setService] = useState<string>(defaultCurrentType ? defaultCurrentType : defaultValues.serviceType)
  const [addons, setAddons] = useState<Array<string>>(defaultCurrentAddons ? defaultCurrentAddons : defaultValues.addons)
  const [getTitle, setTitle] = useState<string>(defaultCurrentTitle ? defaultCurrentTitle : defaultValues.currentTitle)

  const updateCurrentStep = (step: number) => {
    setCurrentStep(step)
  }

  const updateServiceType = (type: string) => {
    setService(type)
  }

  const addAddons = (addon: string) => {
    !!addons ? setAddons([...addons, addon]) : setAddons([addon])
  }

  const removeAddons = (addon: string) => {
    setAddons(addons.filter((v) => v !== addon))
  }

  const updateTitle = (type: string) => {
    setTitle(type)
  }

  const incrementStep = () => {
    setCurrentStep((nextStep) => nextStep + 1)
    router.push(`/?step=${currentStep + 1}&type=${router.query.type}&organizationId=${router.query.organizationId}&isQuotation=${router.query.isQuotation}`)
  }

  const decrementStep = () => {
    setCurrentStep((previousStep) => previousStep - 1)
    router.push(`/?step=${currentStep - 1}&type=${router.query.type}&organizationId=${router.query.organizationId}&isQuotation=${router.query.isQuotation}`)
  }

  const goToStep = (step: SetStateAction<number>) => {
    setCurrentStep(step)
    router.push(`/?step=${step}&type=${router.query.type}&organizationId=${router.query.organizationId}&isQuotation=${router.query.isQuotation}`)
  }

  const contextValue: CurrentStepProviderType = {
    currentStep: currentStep,
    serviceType: serviceType,
    currentTitle: getTitle,
    addons: addons,
    updateServiceType: updateServiceType,
    updateCurrentStep: updateCurrentStep,
    incrementStep: incrementStep,
    decrementStep: decrementStep,
    updateTitle: updateTitle,
    goToStep: goToStep,
    addAddons: addAddons,
    removeAddons: removeAddons,
  }

  return <CurrentStepContext.Provider value={contextValue}>{children}</CurrentStepContext.Provider>
}

export const CreateCurrentStepContext = () => {
  return createContext<CurrentStepProviderType>(defaultValues)
}

export const useCurrentStepContext = () => useContext(CurrentStepContext)
