import { createContext, HTMLAttributes, useContext, useState } from 'react'

const defaultValues: MoveDateContextType = {
  movingDate: new Date(),
  updateMovingDate: () => {},
}

export type MoveDateContextType = {
  movingDate: Date
  updateMovingDate: (arg0: Date) => void
}

export type MoveDateContextProps = {
  defaultMovingDate?: Date
  context: React.Context<MoveDateContextType>
} & HTMLAttributes<HTMLDivElement>

const MoveDateContext = createContext(defaultValues)

export const MoveDateProvider = ({ children, defaultMovingDate }: MoveDateContextProps) => {
  const [movingDate, setMovingDate] = useState<Date>(defaultMovingDate ? defaultMovingDate : defaultValues.movingDate)

  const updateMovingDate = (newDate: Date) => {
    setMovingDate(newDate)
  }

  const contextValue: MoveDateContextType = {
    movingDate: movingDate,
    updateMovingDate: updateMovingDate,
  }

  return <MoveDateContext.Provider value={contextValue}>{children}</MoveDateContext.Provider>
}

export const CreateMoveDateContext = () => {
  return createContext<MoveDateContextType>(defaultValues)
}

export const useMoveDateContext = () => useContext(MoveDateContext)
