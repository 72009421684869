import { AdditionalServices } from '@/types/AdditionalServices'
import { createContext, HTMLAttributes, useContext, useState } from 'react'

export const defaultValues: AddonProviderType = {
  additionalServicesInfo: {
    storage: false,
    packing: false,
    handyman: false,
    cleaning: false,
    moving: false,
  },
  updateAdditionalInfo: () => {},
}

export type AddonProviderType = {
  additionalServicesInfo: AdditionalServices
  updateAdditionalInfo: (arg0: AdditionalServices) => void
}

export type AddonProviderProps = {
  defaultAdditionalServices?: AdditionalServices
  context: React.Context<AddonProviderType>
} & HTMLAttributes<HTMLDivElement>

const AddonContext = createContext(defaultValues)

export const AddonProvider = ({ children, defaultAdditionalServices }: AddonProviderProps) => {
  const [addonInfo, setAddonInfo] = useState<AdditionalServices>(defaultAdditionalServices ? defaultAdditionalServices : defaultValues.additionalServicesInfo)

  // Updates the context with selected additional services
  const updateAdditionalInfo = (data: AdditionalServices) => {
    const updatedAddonObject = {
      ...addonInfo,
      ...data,
    }
    setAddonInfo(updatedAddonObject)
  }

  const contextValue: AddonProviderType = {
    additionalServicesInfo: addonInfo,
    updateAdditionalInfo: updateAdditionalInfo,
  }

  return <AddonContext.Provider value={contextValue}>{children}</AddonContext.Provider>
}

export const CreateAddonContext = () => {
  return createContext<AddonProviderType>(defaultValues)
}

export const useAddonContext = () => useContext(AddonContext)
