import { createContext, HTMLAttributes, useContext, useState } from 'react'

const defaultValues: SupplierIdContextType = {
  supplierId: '',
  updateSupplierId: () => {},
}

export type SupplierIdContextType = {
  supplierId: string
  updateSupplierId: (arg0: string) => void
}

export type SupplierIdContextProps = {
  defaultSupplierId?: string
  context: React.Context<SupplierIdContextType>
} & HTMLAttributes<HTMLDivElement>

const SupplierIdContext = createContext(defaultValues)

export const SupplierIdProvider = ({ children, defaultSupplierId }: SupplierIdContextProps) => {
  const [supplierId, setSupplierId] = useState<string>(defaultSupplierId ? defaultSupplierId : defaultValues.supplierId)

  const updateSupplierId = (id: string) => {
    setSupplierId(id)
  }

  const contextValue: SupplierIdContextType = {
    supplierId: supplierId,
    updateSupplierId: updateSupplierId,
  }

  return <SupplierIdContext.Provider value={contextValue}>{children}</SupplierIdContext.Provider>
}

export const CreateSupplierIdContext = () => {
  return createContext<SupplierIdContextType>(defaultValues)
}

export const useSupplierIdContext = () => useContext(SupplierIdContext)
