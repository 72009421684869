import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import { CreateCurrentStepContext, CurrentStepProvider } from '@/common/context/steps/CurrentStepProvider'
import { CreateMoveDateContext, MoveDateProvider } from '@/common/context/moveDate/MoveDateProvider'
import { CreateFormAddressContext, FormAddressProvider } from '@/common/context/addressForm/AddressFormProvider'
import { CreateAddonContext, AddonProvider } from '@/common/context/addon/AddonProvider'
import { CreateSupplierIdContext, SupplierIdProvider } from '@/common/context/supplierId/SupplierIdProvider'
import { ContactInfoProvider, CreateContactInfoContext } from '@/common/context/contactInfo/ContactInfoProvider'
import { Analytics } from '@vercel/analytics/react'
const contextObject = CreateCurrentStepContext()
const contextMoveDateObject = CreateMoveDateContext()
const contextFormAddressObject = CreateFormAddressContext()
const contextAddonObject = CreateAddonContext()
const contextContactInfoObject = CreateContactInfoContext()
const contextSupplierIdObject = CreateSupplierIdContext()

function App({ Component, pageProps }: AppProps) {
  return (
    <CurrentStepProvider context={contextObject}>
      <FormAddressProvider context={contextFormAddressObject}>
        <MoveDateProvider context={contextMoveDateObject}>
          <ContactInfoProvider context={contextContactInfoObject}>
            <AddonProvider context={contextAddonObject}>
              <SupplierIdProvider context={contextSupplierIdObject}>
                <Component {...pageProps} />
                <Analytics />
              </SupplierIdProvider>
            </AddonProvider>
          </ContactInfoProvider>
        </MoveDateProvider>
      </FormAddressProvider>
    </CurrentStepProvider>
  )
}

export default appWithTranslation(App)
