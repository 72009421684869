import { createContext, HTMLAttributes, useContext, useState } from 'react'

export const defaultValues: FormAddressProviderType = {
  currentAddressInfo: {
    street: '',
    streetNumber: '',
    apartmentNumber: '',
    zip: '',
    city: '',
    residenceType: 'apartment',
    residenceSize: 0,
    elevatorSize: 'none',
    sizeOfAdditionalSpace: 0,
    limitedAccessibility: '',
    autoCompleteAddress: '',
    doorCode: '',
    floor: 0,
    limitedAccessibilityCustomerNotes: '',
  },
  newAddressInfo: {
    street: '',
    streetNumber: '',
    apartmentNumber: '',
    zip: '',
    city: '',
    residenceType: 'apartment',
    residenceSize: 0,
    elevatorSize: 'none',
    sizeOfAdditionalSpace: 0,
    limitedAccessibility: '',
    autoCompleteAddress: '',
    doorCode: '',
    floor: 0,
    limitedAccessibilityCustomerNotes: '',
  },
  updateCurrentAddress: () => {},
  updateNewAddress: () => {},
  sendAddressInformation: () => {},
}

export type FormAddressProviderType = {
  // ? TODO: NEW TYPE FOR CURRENT ADDRESS
  currentAddressInfo: AddressInfo
  newAddressInfo: AddressInfo
  updateCurrentAddress: (arg0: AddressInfo) => void
  updateNewAddress: (arg0: AddressInfo) => void
  sendAddressInformation: (arg0: AddressInfo) => void
}

export type FormAddressProviderProps = {
  defaultNewAddress?: AddressInfo
  defaultCurrentAddress?: AddressInfo
  context: React.Context<FormAddressProviderType>
} & HTMLAttributes<HTMLDivElement>

const FormAddressContext = createContext(defaultValues)

export const FormAddressProvider = ({ children, defaultCurrentAddress, defaultNewAddress }: FormAddressProviderProps) => {
  const [newAddress, setNewAddress] = useState<AddressInfo>(defaultNewAddress ? defaultNewAddress : defaultValues.newAddressInfo)
  const [currentAddress, setCurrentAddress] = useState<AddressInfo>(defaultCurrentAddress ? defaultCurrentAddress : defaultValues.currentAddressInfo)

  const updateNewAddress = (data: AddressInfo) => {
    const updatedAddressObject = {
      ...newAddress,
      ...data,
    }
    setNewAddress(updatedAddressObject)
  }

  const updateCurrentAddress = (data: AddressInfo) => {
    const updatedAddressObject = {
      ...currentAddress,
      ...data,
    }
    setCurrentAddress(updatedAddressObject)
  }


  // MAYBE USE TO SEND ALL DATA!
  const sendAddressInformation = () => {}

  const contextValue: FormAddressProviderType = {
    currentAddressInfo: currentAddress,
    newAddressInfo: newAddress,
    updateNewAddress: updateNewAddress,
    updateCurrentAddress: updateCurrentAddress,
    sendAddressInformation: sendAddressInformation,
  }

  return <FormAddressContext.Provider value={contextValue}>{children}</FormAddressContext.Provider>
}

export const CreateFormAddressContext = () => {
  return createContext<FormAddressProviderType>(defaultValues)
}

export const useFormAddressContext = () => useContext(FormAddressContext)
